import React, { Component } from "react";
//import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class NicoServiceDetails extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <React.Fragment>
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-left pt--100">
                  <h2 className="title">Cleaning Services</h2>
                  <p>
                    All our services includes: Cleaning stove, microwave inside,
                    refrigerator outside, cabinets outside, toilets, bathtub or
                    shower, mirrors, sinks, dusting of all furniture, brooms and
                    accessible surfaces, vacuuming all floors and mopping.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row service-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src="/assets/images/service/cleaning-service.jpg"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Deep cleaning</h4>
                          <p>
                            The objective of this service is to clean in depth
                            and reach those spaces where it is not usually
                            cleaned regularly. It is about moving as much
                            furniture as possible to be able to vacuum and clean
                            the accumulated dirt and grease.
                          </p>
                          <h4 className="title">Regular cleaning</h4>
                          <p>
                            After the deep cleaning, the idea is to use the
                            regular service so that cleaning lasts. So here the
                            service is not in depth but rather focuses more on
                            the details that make your house or business always
                            clean.
                          </p>
                          <h4 className="title">Move-in/out cleaning</h4>
                          <p>
                            It consists of cleaning an empty house, perfect in
                            cases of moving. We clean from the ceiling to the
                            floor, from the entrance to the last room.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row service-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          {/* <h4 className="title">Post-construction cleaning</h4>
                          <p>
                            After a remodel process, all surfaces are covered
                            with a lot of dust and dirt. In this case we take
                            care of cleaning even the smallest corner so that
                            your home is shiny.
                          </p>
                          <h4 className="title">
                            Snow removal and ice control
                          </h4>
                          <p>
                            We take care of removing all the snow from your home
                            or business and controlling the creation of ice so
                            that no one is harmed on your property.
                          </p> */}
                          <h4 className="title">
                            Packing and unpacking service
                          </h4>
                          <p>
                            This service is aimed at those customers who want to
                            skip the boring part of moving. We will unpack for
                            you, from the boxes with the pots in the kitchen to
                            the boxes with the things in the rooms. We've got
                            all the unpacking covered.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/service/snow-removal.jpg"
                            alt="Service Images"
                          />
                          {/*  <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="ZOoVOfieAF8"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button> */}
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default NicoServiceDetails;
