import React, { Component } from "react";
//import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class DeepCleaning extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <React.Fragment>
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-left pt--100">
                  <h2 className="title">Deep cleaning</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row service-details-content pb--40 pt--40 ">
                      <div className="col-lg-6 col-12 order-lg-1 order-2">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src="/assets/images/deep-cleaning/image-2.png"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-lg-2 order-1">
                        <div className="details mt_md--30 mt_sm--30 mb_sm--30">
                          <h4 className="title">Description</h4>
                          <p style={{ textAlign: "justify" }}>
                            This service seeks, not only that your house or
                            office look clean, but that they really are. The
                            main objective of deep cleaning is to clean all
                            those parts where daily or weekly cleaning does not
                            reach. In this sense, the cleaning professionals
                            will have the responsibility to move each and every
                            one of the furniture and desks that are in the place
                            (whenever possible) in order to access those
                            difficult places.
                          </p>

                          <p style={{ textAlign: "justify" }}>
                            We will dust the crown moldings, ceiling lights
                            (only if it is safe), walls, the rest of the
                            moldings and baseboards, if you want to include
                            cleaning your windows in your service, then we will
                            vacuum the rails and clean the glass inside and
                            outside. If you want to include the cleaning of the
                            heating radiators, we will vacuum them and clean
                            them as best as possible.
                          </p>

                          <p style={{ textAlign: "justify" }}>
                            After moving as much furniture as possible, it will
                            be vacuumed, mopped and then everything will be put
                            back in its place. Some clients will want to change
                            their bed makings, and we'd love to do it. This is
                            done in each and every one of the rooms that you
                            wish to include in the service.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--40 pt--40">
                      <div className="col-lg-6 col-12 order-1 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30 mb_sm--30">
                          <p style={{ textAlign: "justify" }}>
                            As for the kitchen, the deep cleaning service
                            includes moving the refrigerator, microwave and
                            stove if possible to clean all that grease and
                            spoiled food that may have fallen behind or under
                            it, after the daily use of your kitchen.
                          </p>
                          <p style={{ textAlign: "justify" }}>
                            The bathroom is the most important part for us
                            because it is where all kinds of bacteria and fungi
                            can accumulate, in the Deep cleaning of your
                            bathroom, we will take care of cleaning the toilet,
                            the bathtub walls, the floor tiles, the doors of
                            glass, the mirrors, the sink, the bathtub and the
                            tub. (does not include cleaning of wooden cabinet
                            inside)
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-2 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/deep-cleaning/image-1.png"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default DeepCleaning;
