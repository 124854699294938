import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>Ready To Do This?</span>
                    <h2>
                      Let's get <br /> to work
                    </h2>
                    <a className="rn-button-style--2" href="tel:+13472473750">
                      <span>
                        Call us at{" "}
                        <span className="bolder-text">+1 (347) 247-3750 </span>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="6">
                  <div className="row">
                    {/* Start Single Widget */}
                    <div className="col-lg-12">
                      <div className="footer-style-2">
                        {/* <h4>Where we work?</h4> */}
                        <p>
                          Nicos Cleaning Services Corp is a company registered
                          and insured in the State of New York.
                        </p>
                      </div>
                    </div>
                    {/* End Single Widget */}
                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>Copyright © 2022 Preztel. All Rights Reserved.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
