// React Required
import React, { Component } from "react";
import ReactDOM from "react-dom";

// Create Import File
import "./index.scss";

import PageScrollTop from "./component/PageScrollTop";

import error404 from "./elements/error404";

import { BrowserRouter, Switch, Route } from "react-router-dom";

import NicosCleaningServices from "./pages/NicosCleaningServices";
import NicoServiceDetails from "./pages/NicoServiceDetails";

import * as serviceWorker from "./serviceWorker";
import DeepCleaning from "./pages/DeepCleaning";
import PackingAndUnpacking from "./pages/PackingAndUnpacking";
import MoveInAndOut from "./pages/MoveInAndOut";
/* import SnowRemoval from "./pages/SnowRemoval";
import PostConstruction from "./pages/PostConstruction"; */
import RegularService from "./pages/RegularService";

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/`}
              component={NicosCleaningServices}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/service-details`}
              component={NicoServiceDetails}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/deep-cleaning`}
              component={DeepCleaning}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/packing-unpacking`}
              component={PackingAndUnpacking}
            />

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/move-in-out`}
              component={MoveInAndOut}
            />

            {/*   <Route
              exact
              path={`${process.env.PUBLIC_URL}/post-construction`}
              component={PostConstruction}
            /> */}

            {/*  <Route
              exact
              path={`${process.env.PUBLIC_URL}/snow-removal`}
              component={SnowRemoval}
            /> */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/regular-service`}
              component={RegularService}
            />

            <Route
              path={`${process.env.PUBLIC_URL}/404`}
              component={error404}
            />

            <Route component={error404} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
serviceWorker.register();
