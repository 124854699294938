import React, { Component } from "react";
//import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class RegularService extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <React.Fragment>
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-left pt--100">
                  <h2 className="title">Regular Service</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row service-details-content pb--80 align-items-center">
                      <div className="col-lg-12 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Description</h4>
                          <p style={{ textAlign: "justify" }}>
                            The regular cleaning service is the one that is
                            carried out regularly to allow the cleaning and
                            organization of the home to last over time, this
                            cleaning service has 3 ways to do it: once a week,
                            once every two weeks or once a month, the price will
                            depend on the one you choose.
                          </p>

                          <p style={{ textAlign: "justify" }}>
                            Regular cleaning always includes your kitchen
                            cabinets outside, the counters, the stove, the
                            fridge outside, the microwave inside, in the
                            bathrooms we will clean your toilet, shower or
                            bathtub, doors, glassdoors, mirrors, walls and
                            zincs, and in the rest of the house, we clean all
                            the dust from the accessible areas, moldings,
                            furniture such as nightstands, cabinets or drawers
                            from the outside, the windows from the inside, we
                            will vacuum all the floors and wash them.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row service-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1 pt--20">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/move-in-out/image-3.png"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2 pt--20">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/move-in-out/image-2.png"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default RegularService;
