import React, { Component } from "react";
import Slider from "react-slick";
import { portfolioSlick } from "../../page-demo/script";

const PortfolioList = [
  {
    image: "image-3",
    link: "/regular-service",
    title: " Regular cleaning",
  },
  {
    image: "image-2",
    link: "/deep-cleaning",
    title: " Deep cleaning",
  },
  {
    image: "image-4",
    link: "/packing-unpacking",
    title: " Unpacking",
  },
  {
    image: "image-6",
    link: "/move-in-out",
    title: " Move-in/out cleaning",
  },
];

class PortfolioTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="portfolio-sacousel-inner mt--60">
          <Slider {...portfolioSlick}>
            {PortfolioList.map((value, index) => (
              <div className="portfolio portfolio-style--2" key={index}>
                <div className="inner">
                  <div className="thumbnail-inner">
                    <div className={`thumbnail ${value.image}`}></div>
                    <div className={`bg-blr-image ${value.image}`}></div>
                  </div>
                  <div className="content">
                    <div className="inner">
                      <p>{value.category}</p>
                      <h4>
                        <a href={value.link}>{value.title}</a>
                      </h4>
                      <a className="rn-btn" href={value.link}>
                        View more
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </React.Fragment>
    );
  }
}
export default PortfolioTwo;
