import React, { Component } from "react";
//import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class PackingAndUnpacking extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <React.Fragment>
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="6"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-left pt--100">
                  <h2 className="title">Packing - Unpacking Service</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row service-details-content pb--40">
                      <div className="col-lg-12 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Description</h4>
                          <p style={{ textAlign: "justify" }}>
                            You are moving soon and you know that the packing,
                            the mess, the cleaning of the new home/office and
                            the unpacking almost have you crazy? Don't worry,
                            we've got you covered. Our packaging system makes
                            unpacking much easier and less cumbersome.
                          </p>

                          <p style={{ textAlign: "justify" }}>
                            Best of all, you'll be part of the process, but you
                            won't have to break a sweat. You must tell us who
                            you are going to assign each room in the new house
                            or office, then we will take care of the rest. How?
                            we explain it to you: We will mark the rooms, the
                            kitchen and the bathrooms with a certain color for
                            each one, then all the belongings that were inside
                            the room, kitchen or bathroom will be marked with
                            that respective color code, in this way, when the
                            people in charge of the move unload your belongings,
                            they will know in which place they should locate
                            them and so when we start unpacking, this will be a
                            piece of cake.
                          </p>

                          <p style={{ textAlign: "justify" }}>
                            As a company we recommend doing a deep cleaning of
                            that new home/office and then being able to unpack
                            all your belongings in clean and disinfected spaces,
                            this will make the move easier. We also recommend
                            getting rid of all that junk and stuff you don't
                            need before you move to avoid moving junk and make
                            the process easier.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row service-details-content ">
                      <div className="col-lg-6 col-12 order-1 order-lg-1 align-items-center pt--20 pb--20">
                        <div className="thumb d-flex justify-content-center">
                          <img
                            className="w-50"
                            src="/assets/images/packing-and-unpacking/image-1.png"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-2 order-lg-2 pt--20 pb--20 ">
                        <div className="thumb d-flex justify-content-center">
                          <img
                            className="w-50"
                            src="/assets/images/packing-and-unpacking/image-2.png"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default PackingAndUnpacking;
