import React, { Component, Fragment } from "react";

import { FiCheck, FiChevronUp } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Slider from "react-slick";
import { VideoTag } from "react-video-tag";
import Footer from "../component/footer/Footer";
import Header from "../component/header/Header";
import { slideSlick } from "../page-demo/script";
//import PortfolioOne from "../elements/portfolio/PortfolioOne";
import PortfolioTwo from "../elements/portfolio/PortfolioTwo";

const SlideList = [
  {
    textPosition: "text-center",
    bgImage: "bg_image--17",
    category: "",
    title: "Nicos Cleaning Services Corp",
    description: "Description",
    /* bulletPoints: [
      "House and Business Cleaning",
      "Moving support",
      "Snow removal",
    ], */
    buttonText: "Contact Us",
    buttonLink: "#contact",
  },
];

/* const PortfolioList = [
  {
    image: "image-3",
    link: "/regular-service",
    title: " Regular cleaning",
  },
  {
    image: "image-2",
    link: "/deep-cleaning",
    title: " Deep cleaning",
  },
  {
    image: "image-4",
    link: "/packing-unpacking",
    title: " Unpacking",
  },
  {
    image: "image-6",
    link: "/move-in-out",
    title: " Move-in/out cleaning",
  },
  {
    image: "image-5",
    link: "/post-construction",
    title: " Post construction cleaning",
  },
  {
    image: "image-1",
    link: "/snow-removal",
    title: " Snow removal and ice control",
  },
]; */

class NicosCleaningServices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    var namesItemOne = [
      "We are focused on the details",
      "We take our time to do a good job, no matter what",
      "We value what the client really wants",
    ];

    return (
      <Fragment>
        <Header homeLink="/" />
        {/* Start Slider Area   */}
        <div id="home" className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                  data-black-overlay="5"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ""}
                          {value.title ? (
                            <h1 className="title">{value.title}</h1>
                          ) : (
                            ""
                          )}

                          {value.bulletPoints ? (
                            <ul className="bullet-points">
                              {value.bulletPoints.map((bullet) => (
                                <li>{bullet}</li>
                              ))}
                            </ul>
                          ) : null}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-solid"
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start About Area  */}
        <div id="about" className="rn-about-area ptb--120 bg_color--1">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5 order-1 order-lg-1">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">About Us</h2>
                    <p className="description" style={{ textAlign: "justify" }}>
                      Nicos Cleaning Services Corp is a corporation that was
                      created to satisfy the true needs of the client, where
                      results and satisfaction are the most important thing for
                      us. We offer detail-oriented cleaning services to all New
                      York homes and businesses in the boroughs of Manhattan,
                      Brooklyn, Queens, Bronx, Staten Island and Long Island.
                    </p>
                  </div>
                  <div className="mt--30 mb--30">
                    <h4>What makes us different?</h4>
                    <ul className="list-style--1">
                      {namesItemOne.map((name, index) => {
                        return (
                          <li key={index}>
                            <FiCheck /> {name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 order-2 order-lg-2">
                {/* <div className="thumbnail position-relative"> */}
                <VideoTag
                  className="custom-video"
                  autoPlay={`${true}`}
                  muted={`${true}`}
                  playsInline={`${true}`}
                  loop={`${true}`}
                  src={`${"/assets/videos/cleaning.mp4"}`}
                  poster={`${"/assets/images/about/nicos-about.jpg"}`}
                />
              </div>
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start Portfolio Area */}
        <div id="services" className="portfolio-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="section-title text-left service-style--3 mb--30">
                  <h2 className="title">Our services</h2>
                  <p className="description" style={{ textAlign: "justify" }}>
                    All our services include: cleaning stove, microwave inside,
                    refrigerator outside, cabinets outside, toilets, bathtub or
                    shower, mirrors, glasses doors, sinks, dusting of all
                    furniture, and accessible surfaces, vacuuming all floors and
                    mopping.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper portfolio-sacousel-inner mb--55">
            <div className="portfolio-slick-activation mt--30 mt_sm--30">
              <PortfolioTwo />
              {/*  <Slider {...portfolioSlick2}>
                {PortfolioList.map((value, index) => (
                  <div className="portfolio portfolio-style--2" key={index}>
                    <div className="thumbnail-inner">
                      <div className={`thumbnail ${value.image}`}></div>
                      <div className={`bg-blr-image ${value.image}`}></div>
                    </div>
                    <div className="content">
                      <div className="inner">
                        <h4>
                          <a href={value.link}>{value.title}</a>
                        </h4>
                        <div className="portfolio-button">
                          <a className="rn-btn" href={value.link}>
                            View more
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider> */}
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start Footer Style  */}
        <div id="contact">
          <Footer />
        </div>
        {/* End Footer Style  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default NicosCleaningServices;
